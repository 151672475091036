/*
 * @Author:
 * @Description:
 * @Date: 2024-12-05 20:48:14
 * @LastEditors: Please set LastEditors
 * @FilePath: /rhcn-checkout-web/src/utils/tools/doku_banks.js
 */
// import DANA_BANK1_PNG from '@/assets/images/dana/bank1.png';
// import DANA_BANK2_PNG from '@/assets/images/dana/bank2.png';
// import DANA_BANK3_PNG from '@/assets/images/dana/bank3.png';
// import DANA_BANK4_PNG from '@/assets/images/dana/bank4.png';
// import DANA_BANK5_PNG from '@/assets/images/dana/bank5.png';
// import DANA_BANK6_PNG from '@/assets/images/dana/bank6.png';
// import DANA_BANK7_PNG from '@/assets/images/dana/bank7.png';
import VIRTUAL_ACCOUNT_BANK_BCA from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_BCA.png';
import VIRTUAL_ACCOUNT_BANK_BNC from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_BNC.png';
import VIRTUAL_ACCOUNT_BANK_BNI from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_BNI.png';
import VIRTUAL_ACCOUNT_BANK_BRI from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_BRI.png';
import VIRTUAL_ACCOUNT_BANK_BSI from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_BSI.png';
import VIRTUAL_ACCOUNT_BANK_BSS from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_BSS.png';
import VIRTUAL_ACCOUNT_BANK_BTN from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_BTN.png';
import VIRTUAL_ACCOUNT_BANK_CIMB from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_CIMB.png';
import VIRTUAL_ACCOUNT_BANK_Danamon from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_Danamon.png';
import VIRTUAL_ACCOUNT_BANK_Mandiri from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_Mandiri.svg';
import VIRTUAL_ACCOUNT_BANK_Permata from '@/assets/images/doku/VIRTUAL_ACCOUNT_BANK_Permata.png';

export const DOKU_BANK_OPTIONS = [
  { code: 'VIRTUAL_ACCOUNT_BANK_CIMB', image: VIRTUAL_ACCOUNT_BANK_CIMB },
  { code: 'VIRTUAL_ACCOUNT_BANK_BSI', image: VIRTUAL_ACCOUNT_BANK_BSI },
  { code: 'VIRTUAL_ACCOUNT_BANK_BCA', image: VIRTUAL_ACCOUNT_BANK_BCA },
  { code: 'VIRTUAL_ACCOUNT_BANK_BNC', image: VIRTUAL_ACCOUNT_BANK_BNC },
  { code: 'VIRTUAL_ACCOUNT_BANK_BNI', image: VIRTUAL_ACCOUNT_BANK_BNI },
  { code: 'VIRTUAL_ACCOUNT_BANK_Mandiri', image: VIRTUAL_ACCOUNT_BANK_Mandiri },
  { code: 'VIRTUAL_ACCOUNT_BANK_BRI', image: VIRTUAL_ACCOUNT_BANK_BRI },

  { code: 'VIRTUAL_ACCOUNT_BANK_BTN', image: VIRTUAL_ACCOUNT_BANK_BTN },
  { code: 'VIRTUAL_ACCOUNT_BANK_Permata', image: VIRTUAL_ACCOUNT_BANK_Permata },
  { code: 'VIRTUAL_ACCOUNT_BANK_Danamon', image: VIRTUAL_ACCOUNT_BANK_Danamon },
  { code: 'VIRTUAL_ACCOUNT_BANK_BSS', image: VIRTUAL_ACCOUNT_BANK_BSS },
];

// VIRTUAL_ACCOUNT_BANK_CIMB;
// VIRTUAL_ACCOUNT_BANK_BSI;
// VIRTUAL_ACCOUNT_BANK_BCA;
// VIRTUAL_ACCOUNT_BANK_BNC;
// VIRTUAL_ACCOUNT_BANK_BNI;
// VIRTUAL_ACCOUNT_BANK_Mandiri;
// VIRTUAL_ACCOUNT_BANK_BRI;
// VIRTUAL_ACCOUNT_BANK_BTN;
// VIRTUAL_ACCOUNT_BANK_Permata;
// VIRTUAL_ACCOUNT_BANK_Danamon;
// VIRTUAL_ACCOUNT_BANK_BSS;
