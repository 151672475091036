import Vue from 'vue';
import VueRouter from 'vue-router';

import { loadLanguageAsync } from '@/language/index';
import Layout from '@/layout/base/index.vue';
import LayoutUni18n from '@/layout/uni18n/index.vue';
import store from '@/store';

Vue.use(VueRouter);
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err);
// };

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Layout,
    children: [
      {
        path: 'address/:type',
        name: 'Address',
        component: () => import('@/views/address/index.vue'),
      },
      {
        path: 'address/:type/:id',
        name: 'Address Old',
        component: () => import('@/views/address/index.vue'),
      },
      {
        path: 'checkout',
        name: 'Checkout',
        component: () => import('@/views/checkout/index.vue'),
      },

      {
        path: 'checkout/:id',
        name: 'Checkout Old',
        component: () => import('@/views/checkout/index.vue'),
      },
      {
        path: 'replaceTheCard',
        name: 'replaceTheCard',
        component: () => import('@/views/replaceTheCard/index.vue'),
      },
      {
        path: 'aggregate',
        name: 'Aggregate Payment',
        component: () => import('@/views/aggregate/index.vue'),
      },
      {
        path: 'aggregate/:id',
        name: 'Aggregate Payment Old',
        component: () => import('@/views/aggregate/index.vue'),
      },
      {
        path: 'wx/m',
        name: 'Wechat Mobile Payment Pending',
        meta: {
          showLogo: true,
        },
        component: () => import('@/views/wechat/payment-pending-mobile.vue'),
      },
      {
        path: 'wx/m/:id',
        name: 'Wechat Mobile Payment Pending Old',
        meta: {
          showLogo: true,
        },
        component: () => import('@/views/wechat/payment-pending-mobile.vue'),
      },
      {
        path: 'wx',
        name: 'Wechat QRCODE',
        meta: {
          showLogo: false,
        },
        component: () => import('@/views/wechat/payment-pc.vue'),
      },
      {
        path: 'wx/:id',
        name: 'Wechat QRCODE Old',
        meta: {
          showLogo: false,
        },
        component: () => import('@/views/wechat/payment-pc.vue'),
      },
      {
        path: 'refund/info',
        name: 'Refund info',
        meta: {
          showHeader: false,
        },
        component: () => import('@/views/refund/info.vue'),
      },
      {
        path: 'refund/success',
        name: 'Refund success',
        meta: {
          showHeader: false,
        },
        component: () => import('@/views/refund/success.vue'),
      },
      // 微信支付的二维码
      {
        path: 'payment/qrcode',
        name: 'Payment qrcode',
        meta: {
        },
        component: () => import('@/views/payment/qrcode.vue'),
      },
      {
        path: 'payment/local',
        name: 'Payment local',
        meta: {
          showHeader: false,
          contentStyle: 'padding-top:0px',
        },
        component: () => import('@/views/payment/local.vue'),
      },

      {
        path: 'payment/local/qrcode',
        name: 'Payment dlocal qrcode',
        meta: {
          showHeader: false,
          contentStyle: 'padding-top:0px',
        },
        component: () => import('@/views/payment/local-qrcode.vue'),
      },
      {
        path: 'payment/local/dana',
        name: 'Payment dlocal dana',
        meta: {
          showHeader: true,
          lang: ['en', 'in'],
        },
        component: () => import('@/views/payment/local-dana.vue'),
      },
      {
        path: 'payment/local/doku',
        name: 'Payment dlocal doku',
        meta: {
          showHeader: true,
          lang: ['en'],
        },
        component: () => import('@/views/payment/local-doku.vue'),
      },
      {
        path: 'payment/local/qris',
        name: 'Payment local qris',
        meta: {
          showHeader: true,
        },
        component: () => import('@/views/payment/local-qris.vue'),
      },
      {
        path: 'payment/palmpay',
        name: 'Payment PalmPay',
        meta: {
          showHeader: false,
          contentStyle: 'padding-top:0px',
        },
        component: () => import('@/views/payment/palmpay.vue'),
      },
      {
        path: 'test',
        name: 'Payment',
        meta: {
        },
        component: () => import('@/views/googlePayTest.vue'),
      },
      {
        path: 'payment/dlocal',
        name: 'Payment dlocal',
        meta: {
          showHeader: false,
          contentStyle: 'padding-top:0px',
        },
        component: () => import('@/views/payment/dlocal.vue'),
      },
    ],
  },
  {
    path: '/result',
    name: 'Result',
    component: LayoutUni18n,
    children: [
      {
        path: 'pending',
        name: 'Pending',
        component: () => import('@/views/result/pending.vue'),
      },
      {
        path: ':id/pending',
        name: 'Pending Old',
        component: () => import('@/views/result/pending.vue'),
      },
      {
        path: 'success',
        name: 'Success',
        component: () => import('@/views/result/success.vue'),
      },
      {
        path: ':id/success',
        name: 'Success Old',
        component: () => import('@/views/result/success.vue'),
      },
      {
        path: 'failed',
        name: 'Failed',
        component: () => import('@/views/result/failed.vue'),
      },
      {
        path: ':id/failed',
        name: 'Failed Old',
        component: () => import('@/views/result/failed.vue'),
      },
      {
        path: 'unknow',
        name: 'Unknow',
        component: () => import('@/views/result/unknow.vue'),
      },
      {
        path: ':id/unknow',
        name: 'Unknow Old',
        component: () => import('@/views/result/unknow.vue'),
      },
      {
        path: 'timeout',
        name: 'Timeout',
        component: () => import('@/views/result/timeout.vue'),
      },
      {
        path: ':id/timeout',
        name: 'Timeout Old',
        component: () => import('@/views/result/timeout.vue'),
      },
      {
        path: 'invalid',
        name: 'Invalid',
        component: () => import('@/views/result/invalid.vue'),
      },
      {
        path: ':id/invalid',
        name: 'Invalid Old',
        component: () => import('@/views/result/invalid.vue'),
      },
      {
        path: 'cancelled',
        name: 'Cancelled',
        component: () => import('@/views/result/cancelled.vue'),
      },
      {
        path: ':id/cancelled',
        name: 'Cancelled Old',
        component: () => import('@/views/result/cancelled.vue'),
      },
      {
        path: 'error',
        name: 'Payment Error',
        component: () => import('@/views/result/error.vue'),
      },

    ],
  },
  {
    path: '/v1',
    name: 'Install',
    component: LayoutUni18n,
    children: [
      // shoplazza
      {
        path: 'plugin/appInstall/shoplazza/payment_app_rh',
        name: 'shoplazza payment_app_rh install',
        component: () => import('@/views/plugin/shoplazza/install.vue'),
      },
      {
        path: 'plugin/appInstall/shoplazza/payment_app_lpms',
        name: 'shoplazza payment_app_lpms install',
        component: () => import('@/views/plugin/shoplazza/install.vue'),
      },
      {
        path: 'plugin/appInstall/shoplazza/success',
        name: 'shoplazza payment_app install success',
        component: () => import('@/views/plugin/shoplazza/success.vue'),
      },
      {
        path: 'plugin/appInstall/shoplazza/failed',
        name: 'shoplazza payment_app install failed',
        component: () => import('@/views/plugin/shoplazza/failed.vue'),
      },

      // shopline
      {
        path: 'plugin/appInstall/shopline/payment_app_activate',
        name: 'shopline payment_app_activate install',
        component: () => import('@/views/plugin/shopline/install.vue'),
      },
      {
        path: 'plugin/appInstall/shopline/success',
        name: 'shopline payment_app install success',
        component: () => import('@/views/plugin/shopline/success.vue'),
      },
      {
        path: 'plugin/appInstall/shopline/failed',
        name: 'shopline payment_app install failed',
        component: () => import('@/views/plugin/shopline/failed.vue'),
      },
      {
        path: 'plugin/appInstall/shopline/loading',
        name: 'shopline payment_app install loading',
        component: () => import('@/views/plugin/shopline/loading.vue'),
      },
      // {
      //   path: 'plugin/appInstall/shopline/iframe-active',
      //   name: 'shopline payment_app install iframe-active',
      //   component: () => import('@/views/plugin/shopline/iframe-active.vue'),
      // },
    ],
  },
  {
    path: '/error',
    name: 'error',
    component: LayoutUni18n,
    redirdct: '/error/404',
    children: [
      {
        path: '404',
        name: '404',
        component: () => import('@/views/error/404.vue'),
      },
    ],
  },
  {
    path: '/v1/plugin/appInstall/shopline/iframe-active',
    name: 'shopline payment_app install iframe-active',
    component: () => import('@/views/plugin/shopline/iframe-active.vue'),
    redirdct: '/error/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    return next({
      path: '/error/404',
      replace: true,
    });
  }
  await loadLanguageAsync(store.getters.app.currentLan);
  next();
});

export default router;
