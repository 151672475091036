<!--
 * @Author:
 * @Description:DOKU支付页面
 * @Date: 2024-10-22 09:50:48
 * @LastEditors: Please set LastEditors
 * @FilePath: /rhcn-checkout-web/src/views/payment/local-doku.vue
-->
<template>
  <div ref="local" id="local" class="local-wrapper">
    <div class="saq-wrapper" v-loading.fullscreen="fullscreenLoading">
      <!-- TODO -->
      <div class="content-wrapper" v-if="paymentInfo">
        <div class="info-wrapper">
          <div class="info pc">
            <el-row>
              <el-col :span="12">
                <div class="item">
                  <div class="head">{{ $t('message.checkout.amount') }}</div>
                  <div class="content">{{ paymentInfo.txnCurrency }} {{ paymentInfo.txnAmount }}</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="item">
                  <div class="head">{{ $t('message.dana.validUntil') }} :</div>
                  <div class="content">{{ paymentInfo.presentContext.validUpTo }}
                    <span style="font-size: 16px;">UTC/GMT+07:00</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="info mobile">
            <div class="item margin-bottom-6">
              <div class="head">{{ $t('message.checkout.amount') }}:</div>
              <div class="content">{{ paymentInfo.txnCurrency }} {{ paymentInfo.txnAmount }}</div>
            </div>
            <div class="item">
              <div class="head">{{ $t('message.dana.validUntil') }} :</div>
              <div class="content">{{ paymentInfo.presentContext.validUpTo }}
                <span style="font-size: 16px;">UTC/GMT+07:00</span>
              </div>
            </div>
          </div>
          <div class="qrcode">
            <div class="placeholder_info">
              <div style="font-weight: 600;">{{ $t('message.dana.placeholderInfo1') }}</div>
              <div>{{ $t('message.dana.placeholderInfo2') }}</div>
            </div>

            <div class="bank-wrap">
              <div class="title">{{ $t('message.dana.bankName') }}</div>
              <div class="bank-img"><img :src="bankImage" alt=""></div>
              <div class="title">{{ $t('message.dana.accountNumber') }}</div>
              <div class="accountNumber">{{ paymentInfo.presentContext.payerVirtualAccNo }}</div>

            </div>

            <div class="copy">
              <el-button @click="copyTicket" class="button" type="primary">
                {{ $t('message.dana.copyBtn') }}
                <i style="margin-left: 3px;" class="iconfont icon-rongqi"></i>
              </el-button>

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>

import { getLocalPaymentInfo } from '@/api/checkout';
import { loadLanguageAsync } from '@/language/index';
import { DOKU_BANK_OPTIONS } from '@/utils/tools/doku_banks';
import { copyString } from '@/utils/tools/utils';

export default {

  data() {
    return {
      DOKU_BANK_OPTIONS,
      fullscreenLoading: true,
      bankImage: '',
      txnId: '',
      paymentInfo: null,
      showErrorMsg: false,
      showCardName: false,
    };
  },
  created() {
    // 默认英文
    loadLanguageAsync('en');
    this.$bus.$emit('lang_components', 'select_language');
    this.$bus.$emit('lang_components', 'click_language_dropdown');

    this.txnId = this.$route.query.txnId;
    getLocalPaymentInfo(this.txnId).then((res) => {
      this.fullscreenLoading = false;
      if (res.respCode === '20000') {
        this.paymentInfo = res.data;
        this.bankImage = this.DOKU_BANK_OPTIONS.find(item => item.code === res.data.presentContext.bankName).image;
        if (res.data.brandConfig) {
          this.$store.dispatch('app/setBrandInfo', res.data.brandConfig);
        }
        if (!res.data) {
          this.showErrorMsg = true;
        }
      } else {
        this.showErrorMsg = true;
      }
    }).catch(() => {
      this.showErrorMsg = true;
      this.fullscreenLoading = false;
    });
  },

  methods: {
    copyTicket() {
      copyString(this.paymentInfo.presentContext.payerVirtualAccNo, this.$t('message.dlocal.copySuccess'));
    },
  },
};
</script>
<style lang="scss">
.bank-wrap {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  margin: 20px auto;

  .title {
    font-weight: 600;
    margin: 20px 0;
  }

  .bank-img {
    img {
      width: 200px;
    }
  }

  .accountNumber {
    font-size: 32px;
    font-weight: bold;
  }
}

.local-wrapper {
  min-height: calc(100vh - 80px);
  background-color: white;
}

.saq-wrapper {
  width: 1030px;
  max-width: 100%;
  margin: 0 auto;

  // height: 500px;
  .content-wrapper {
    padding: 0 15px;

    // height: 100px;
    .logos {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .card-logo {
        height: 50px;
      }

      .merchant-logo {
        height: 50px;
      }
    }

    .info-wrapper {
      text-align: center;
      background: #FAFAFA;
      padding-bottom: 30px;
    }

    .info {
      background: #EEEEEE;
      height: 144px;

      &.mobile {
        display: none;
      }

      &.pc {
        display: block;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 144px;
      }

      .head {
        font-weight: 900;
        font-size: 18px;
        color: #27272B;
        line-height: 25px;
      }

      .content {
        font-weight: 900;
        font-size: 28px;
        color: #27272B;
        line-height: 38px;
        margin-top: 4px;
        max-width: 100%;
        white-space: nowrap;
      }
    }

    .qrcode {
      width: 660px;
      max-width: 100%;
      margin: 40px auto 0;

      .ticket-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        gap: 10px;

        .button {
          // display: none;
          padding: 0;

          .icon-rongqi {
            font-size: 20px;
            color: var(--color-primary);
          }
        }
      }

      .ticket {
        border-radius: 2px;
        border: 1px solid #616D7A;
        padding: 0 12px;
        white-space: nowrap;
        overflow-x: scroll;
        text-overflow: ellipsis;
        max-width: 100%;
        font-weight: 500;
        font-size: 20px;
        color: #27272B;
        line-height: 27px;
        height: 43px;
        display: flex;
        align-items: center;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .card {
        font-weight: 900;
        font-size: 24px;
        color: #27272B;
        line-height: 33px;
      }

      .scan {
        margin-top: 40px;
        font-weight: 900;
        font-size: 20px;
        color: #27272B;
        line-height: 27px;
      }

      .img {
        width: 260px;
        height: 260px;
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 0px;
        border: 1px solid #616D7A;
        background: #FFFFFF;

        img {
          height: 100%;
        }
      }

      .copy {
        margin-top: 30px;

        .ticket {
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #616D7A;
          line-height: 42px;
          font-weight: 500;
          font-size: 16px;
          color: #27272B;
          text-align: left;
          padding: 0 12px;
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .button {
          width: 100%;
          // margin-top: 20px;
        }
      }
    }

    .tips {
      text-align: left;
      width: 660px;
      max-width: 100%;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 36px;

      .head {
        font-weight: 900;
        font-size: 16px;
        color: #27272B;
        line-height: 22px;
      }

      .content {
        font-weight: 500;
        font-size: 16px;
        color: #616D7A;
        line-height: 22px;
        margin-top: 13px;
      }
    }

    .placeholder_info {
      padding: 20px 32px;
      background: rgba(255, 243, 243, 1);
    }

    .return {
      padding: 50px 0 130px;
      color: var(--color-primary);
      font-weight: 500;
      font-size: 16px;

      .el-link--primary {
        font-size: 16px;
        line-height: 22px;
        color: var(--color-primary);
      }

      .icon-fanhui {
        margin-right: 5px;
      }
    }
  }

  .error-msg {
    padding: 200px 15px 0;
    width: 690px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;

    .unknow {
      width: 134px;
      height: auto;
    }

    .tips {
      font-weight: 500;
      font-size: 18px;
      color: #616D7A;
      line-height: 25px;
      text-align: left;
      margin-top: 40px;
    }
  }
}

@media screen and(max-width: 1140px) {
  .saq-wrapper {
    .content-wrapper {
      .logos {
        height: 50px;

        .card-logo {
          height: 30px;
        }

        .merchant-logo {
          height: 30px;
        }
      }

      .info {
        height: 70px;

        &.pc {
          display: none;
        }

        &.mobile {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .item {
          height: auto;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 10px;

          .head {
            font-size: 12px;
          }

          .content {
            font-size: 16px;
            line-height: 22px;
          }
        }

        // .head {
        //   font-size: 24px;
        //   line-height: 33px;
        // }
        // .content {
        //   font-size: 32px;
        //   line-height: 44px;
        //   margin-top: 0;
        // }
      }

      .qrcode {
        margin-top: 20px;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;

        .ticket {
          font-size: 12px;
          line-height: 17px;
        }

        .card {
          font-size: 14px;
          line-height: 19px;
        }

        .scan {
          margin-top: 15px;
          font-size: 14px;
          line-height: 19px;
        }

        .copy {
          margin-top: 15px;
        }
      }

      // .qrcode {
      //   margin-top: 30px;
      //   .card {
      //     font-size: 28px;
      //     line-height: 38px;
      //   }
      //   .scan {
      //     font-size: 22px;
      //     line-height: 30px;
      //     margin-top: 5px;
      //   }
      //   .img {
      //     height: 290px;
      //   }
      //   .copy {
      //     margin-top: 50px;
      //     .ticket {
      //       line-height: 58px;
      //       font-size: 22px;
      //     }
      //     .button {
      //       height: 60px;
      //       font-size: 24px;
      //     }
      //   }
      // }
      .tips {
        padding-top: 20px;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;

        .head {
          font-size: 12px;
          line-height: 17px;
        }

        .content {
          font-size: 12px;
          line-height: 17px;
        }
      }

      // .return {
      //   font-size: 24px;
      //   line-height: 33px;
      //   .button {
      //     font-size: 24px;
      //     line-height: 33px;
      //   }
      // }
    }
  }
}
</style>
